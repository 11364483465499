import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import themeUtils from '../lib/themeUtils';
import { Layout, Seo } from '../components/compounds';
import {
  Centered,
  Container,
  Row,
  Col,
  Separator,
  Title,
  EmailLink,
  Section,
} from '../components/blocks';

const TeamPage = ({ data }) => (
  <Layout>
    <Seo title="Team" />
    <Section>
      <Container>
        <Centered>
          <Row column>
            <Col>
              <Title
                css={css`
                  text-align: center;
                `}
              >
                MEET OUR TEAM
              </Title>
            </Col>
            <Col>
              <Separator bottom={false} />
            </Col>
          </Row>
        </Centered>
      </Container>
    </Section>
    <Section
      css={css`
        padding: 0;
      `}
    >
      <Container>
        <Centered>
          <Row column>
            <Col>
              <TeamRow>
                <Col>
                  <StyledTeamMember
                    name="Adam Perlow"
                    email="adam@blockchaindevelopmentltd.com"
                    imageSrc={data.adam.childImageSharp.fluid}
                  />
                </Col>
                <Col>
                  <StyledTeamMember
                    name="Eleanor Milstein"
                    email="eleanor@blockchaindevelopmentltd.com"
                    imageSrc={data.eleanor.childImageSharp.fluid}
                  />
                </Col>
                <Col>
                  <StyledTeamMember
                    name="Lior Frenkel"
                    email="lior@blockchaindevelopmentltd.com"
                    imageSrc={data.lior.childImageSharp.fluid}
                  />
                </Col>
                <Col>
                  <StyledTeamMember
                    name="David Brima"
                    email="david@blockchaindevelopmentltd.com"
                    imageSrc={data.david.childImageSharp.fluid}
                  />
                </Col>
                <Col>
                  <StyledTeamMember
                    name="Ariel Grunfeld"
                    email="ariel@blockchaindevelopmentltd.com"
                    imageSrc={data.ariel.childImageSharp.fluid}
                  />
                </Col>
                <Col>
                  <StyledTeamMember
                    name="Samuel Astrologo"
                    email="sam@blockchaindevelopmentltd.com"
                    imageSrc={data.samuel.childImageSharp.fluid}
                  />
                </Col>
              </TeamRow>
            </Col>
          </Row>
        </Centered>
      </Container>
    </Section>
  </Layout>
);

TeamPage.propTypes = {
  data: PropTypes.object,
};

export default TeamPage;

const TeamRow = styled(Row)`
  justify-content: space-between;
  margin-left: ${themeUtils.spacings.sm};
  ${Col} {
    margin-top: 0;
    margin-right: ${themeUtils.spacings.sm};
    margin-bottom: ${themeUtils.spacings.xl};
    margin-left: 0;
    display: flex;
    justify-content: center;
    flex-basis: 22%;
  }
`;

const TeamMember = ({ name, email, imageSrc, className }) => (
  <div className={className}>
    <div className="image">{imageSrc && <Img fluid={imageSrc} />}</div>
    <div className="details">
      <Title className="name" size="3">
        {name}
      </Title>
      <EmailLink href={`mailto:${email}`}>{email}</EmailLink>
    </div>
  </div>
);
const StyledTeamMember = styled(TeamMember)`
  .image {
    ${themeUtils.mq({
      width: ['260px', null, null, null, 'calc(((100vw - 140px) / 4) - 50px)'],
      height: 'auto',
      maxWidth: '326px',
    })}
    background-color: #000000;
  }
  .details {
    margin-top: ${themeUtils.spacings.md};
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .name {
    text-transform: uppercase;
  }
`;

export const imageShape = graphql`
  fragment imageShape on File {
    childImageSharp {
      fluid(maxWidth: 326, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const query = graphql`
  query {
    default: file(relativePath: { regex: "/team/default/" }) {
      ...imageShape
    }

    adam: file(relativePath: { regex: "/team/adam/" }) {
      ...imageShape
    }

    david: file(relativePath: { regex: "/team/david/" }) {
      ...imageShape
    }

    eleanor: file(relativePath: { regex: "/team/eleanor/" }) {
      ...imageShape
    }

    lior: file(relativePath: { regex: "/team/lior/" }) {
      ...imageShape
    }

    ariel: file(relativePath: { regex: "/team/ariel/" }) {
      ...imageShape
    }

    samuel: file(relativePath: { regex: "/team/samuel/" }) {
      ...imageShape
    }
  }
`;
